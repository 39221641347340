import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Insights from "../components/Insights";
import HeroHigh from "../components/HeroHigh";
import ShopList from "../components/ShopList";
import ServicesFeatures from "../components/ServicesFeatures";
import { transformedData, getRandomDateInLast15Days } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, articles, post, products, curentPage } = usePageData(
    data,
    pageContext
  );
  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={transformedData(curentPage?.metadata)}
        descriptionShort={false}
      />
      <ServicesFeatures
        serviceFeatures={{
          ...post?.servicesFeatures,
          seoKeyword: transformedData(curentPage?.metadata)?.seoKeyword,
        }}
        dark={false}
        keyword={curentPage?.seoKeyword}
      />

      <ShopList
        settings={{
          ...transformedData(curentPage?.metadata),
          ...post?.servicesProcess,
          buttonTitle: transformedData(settings?.listProductsBest)?.buttonTitle,
          buttonLink: transformedData(settings?.listProductsBest)?.buttonLink,
        }}
        products={products}
        productsSettings={transformedData(settings?.products)}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
        light={true}
      />
      <HeroHigh settings={transformedData(settings?.heroInside)} />

      <ServicesFeatures
        serviceFeatures={{
          ...post?.servicesFeatures2,
          seoKeyword: transformedData(curentPage?.metadata)?.seoKeyword,
        }}
        dark={false}
        full={true}
        keyword={curentPage?.seoKeyword}
      />

      <Insights
        articles={articles?.slice(0, 3)}
        full={false}
        light={true}
        settings={transformedData(settings?.news)}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { curentPage } = usePageData(data, pageContext);
  const metaData = transformedData(curentPage?.metadata);
  const image = getImage(
    transformedData(curentPage?.metadata).images[0]?.postURL
  );
  return (
    <Seo
      title={metaData?.seoTitle}
      description={metaData?.seoDescription}
      image={image?.images?.fallback?.src}
      url={"/" + curentPage?.slug + "/"}
      schema={JSON.stringify({
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        name: process.env.NAME,
        url: process.env.PUBLIC_URL,
        headline: metaData?.seoTitle,
        image: [process.env.PUBLIC_URL + image?.images?.fallback?.src],
        author: [
          {
            "@type": "Person",
            name: "Ella Locoman",
          },
        ],
        datePublished:
          new Date(curentPage?.date).toISOString().slice(0, 19) + "+08:00",
        dateModified: getRandomDateInLast15Days(),
      })}
    />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
  }
`;

export default Page;
