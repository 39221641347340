import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.scss";

interface ServicesType {
  serviceFeatures?: any;
  dark: boolean;
  keyword?: string;
  full?: boolean;
}

interface ServiceItemType {
  title: string;
  icon: string;
  content: string;
  content2?: string;
}

const ServicesFeatures: React.FC<ServicesType> = ({
  serviceFeatures,
  dark = true,
  full = false,
  keyword = "",
}) => {
  return (
    <section className={dark ? "services-features" : "services-features light"}>
      <div className={full ? "container full" : "container"}>
        <h3>{serviceFeatures?.seoKeyword}</h3>
        <h2>{serviceFeatures?.title}</h2>
        {serviceFeatures?.content && (
          <p className="services-features-desc">{serviceFeatures?.content}</p>
        )}
        {serviceFeatures?.content2 && (
          <p className="services-features-desc">{serviceFeatures?.content2}</p>
        )}
        <div className="services-features-wrap">
          {serviceFeatures?.list?.map((e: ServiceItemType, i: number) => {
            return (
              <div className="services-features-item" key={i}>
                <div className="services-values-head">
                  {e?.icon && (
                    <img
                      src={`${process.env.PUBLIC_URL}/img/icons/${e?.icon}.svg`}
                      alt={e?.title}
                    />
                  )}
                  <h4 className="item-title">{e.title}</h4>
                </div>
                {e.content && <p className="item-desc">{e.content}</p>}
                {e.content2 && <p className="item-desc">{e.content2}</p>}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ServicesFeatures;
